import React from 'react';
import './App.css';
import { Breadcrumb, ConfigProvider, Layout } from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import ItemList from './questions/item/itemList';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './home/home';
import MbtiQuestions from './questions/mbtiQuestions';
import ResultPage from './questions/mbtiResult';
import Login from './auth/login';
import { Row, Col } from 'antd';
const App: React.FC = () => {
  // const {
  //   token: { colorBgContainer, borderRadiusLG },
  // } = theme.useToken();

  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#00b96b',
        borderRadius: 2,
        colorBgContainer: '#f6ffed'
      }
    }}>
      <Layout>
        {/* <TopMenu /> */}
        <Content style={{ padding: '0 48px' }}>

          <Row justify="space-between" align="middle">
            <Col>
              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>MBTI</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col>
              <Login></Login>
            </Col>
          </Row>


          <Router>
            <div>
              <Routes>
                <Route path="/" Component={HomePage} />
                <Route path="/test" Component={ItemList} />
                <Route path="/mbti" Component={MbtiQuestions} />
                <Route path="/result" element={<ResultPage />} />
              </Routes>
            </div>
          </Router>


          {/* <div style={{
            background: colorBgContainer,
            minHeight:280,
            padding: 24,
            borderRadius: borderRadiusLG
          }}>
          <ItemList></ItemList></div> */}
        </Content>
        <Footer style={{ textAlign: 'center' }}>Powered By WishDiv</Footer>
      </Layout>

    </ConfigProvider>

  );
}

export default App;
