import React, {useEffect} from 'react';

const Login: React.FC = () => {

    useEffect(() => {
        // Load the Google API script
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    
        // Clean up the script tag if necessary
        return () => {
          document.body.removeChild(script);
        };
      }, []);

      const handleLoginResponse = async (e: any) => {
        e.preventDefault();
        console.log('response:'+ JSON.stringify(e))
    }


    return (
        <>
            <div id="g_id_onload"
                data-client_id="263783060586-kuoa26bbutbq8bhnmp2ggu1051opitb7.apps.googleusercontent.com"
                data-login_uri="https://api.it-sides.com/api/mbti/g/callback"
                // data-login_uri="http://localhost:8787/api/mbti/g/callback"
                data-ux_mode="popup"
                data-context="signin"
                data-callback={handleLoginResponse}
                data-auto_prompt="false">
            </div>

            <div className="g_id_signin" data-type="standard"></div>
        </>
    );
};

export default Login;
