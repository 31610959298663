import { Context } from "../../context";
import { useState, useContext } from "react";
import { Card, Radio, RadioChangeEvent, Space } from 'antd'
export default function Item(item: any) {
    const data: any = useContext(Context);
    const [, dispatch] = data;

    const content = item.children

    const [value, setValue] = useState(content.answer);
    const seq = content.id + 1;
    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        if (content.id < 39) {
            dispatch({ type: "editAnswer", payload: { id: content.id, answer: e.target.value } })
            setTimeout(() => {
                item.onChange(seq + 1)
            }, 600);
        } else {
            dispatch({ type: "enableSubmit" })
        }
    }
    
    return (
        <Card title={<div style={{ whiteSpace: 'pre-wrap' }}>{seq + ". " + content.question}</div>}>
            <Radio.Group onChange={onChange} value={value} >
                <Space direction="vertical">
                    <Radio value={1}>{content.answer1}</Radio>
                    <Radio value={2}>{content.answer2}</Radio>
                </Space>
            </Radio.Group>
        </Card>
    );
}