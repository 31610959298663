import { useContext, useState } from "react";
import { Context } from "../../context";
import { List, Pagination } from "antd";
import Item from "./item";


export default function ItemList() {

    const value: any = useContext(Context)
    const [state, ] = value;
    const [currentPage, setCurrentPage] = useState(1);
    const data = state.questionList;
    const itemsPerPage = 1;
    const startIndex = (currentPage - 1) * itemsPerPage
    const paginatedData = data.slice(startIndex, startIndex + itemsPerPage)

    const onChange = (page: number) => {
        setCurrentPage(page);
    }

    return (
        <div>
            <List dataSource={paginatedData} renderItem={(item: any) => <Item onChange={onChange} key={item.id} >{item}</Item>} pagination={false} />
            <Pagination defaultCurrent={1} total={data.length} onChange={onChange} current={currentPage} pageSize={itemsPerPage} showSizeChanger={false} />
        </div>
    );

}