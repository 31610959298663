import { createContext, useReducer } from "react"

export const Context = createContext({});
function QuestionAnswer(id: number, question: string, answer1: string, answer2: string, answer: number, cat: string) {
    return {
        id: id,
        question: question,
        answer1: answer1,
        answer2: answer2,
        answer: answer,
        cat: cat
    }
}

const initialState = { questionList: [], enableSubmit: false };

function reducer(state: any, action: any) {
    switch (action.type) {
        case "loadData":
            return {
                ...state,
                questionList: action.payload.map((item: any) => {
                    return QuestionAnswer(item.id, item.question, item.answer1, item.answer2, 0, item.cat);
                }),
            };
        case "editAnswer":
            return {
                ...state,
                questionList: state.questionList.map((item: any) => {
                    if (item.id === action.payload.id) {
                        return QuestionAnswer(item.id, item.question, item.answer1, item.answer2, action.payload.answer, item.cat)
                    }
                    return item;
                })
            };
        case "enableSubmit":
            //todo check if all questions get answered.
            return {
                ...state,
                enableSubmit: true
            };
        case "nav2result":
            return {
                ...state,
                result: action.payload
            };
        default:
            return state;
    }
}

const Provider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    
    // useEffect(() => {
    //     async function fetchAPI() {
    //         const data = await fetch("data.en.json").then(
    //             response => response.json()
    //         );
    //         dispatch({ type: "loadData", payload: data })
    //     }
    //     // fetchAPI();
    // }, []

    // );
    return (
        <Context.Provider value={[state, dispatch]}>{children} </Context.Provider>
    );
};

export default Provider