// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Alert } from "antd";
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;
const HomePage: React.FC = () => {
  return (
    <div>
      <Title>MBTI Professional Personality Test</Title>
      <div>
        <Alert message="Note: The MBTI personality test provided on this site is the official 72-question free version, and it takes about 5 minutes to complete the test." type="info" showIcon />
        <Title level={3}>Before the test:</Title>

        <Paragraph>
          <ul >
            <ol>
              1. The test participants must answer the questions honestly and independently. Only in this way can they get effective results.
            </ol>
            <ol>
              2. Personality analysis shows your personality tendency, not your knowledge, skills and experience.
            </ol>
            <ol>
              3. The character type description provided by the MBTI personality test is only for the tester to determine his or her own personality type, which is not good or bad, only different. Each personality trait has its value and advantages, as well as shortcomings and areas to pay attention to. A clear understanding of their own personality strengths and weaknesses, conducive to better use of their own strengths, and as far as possible in the conduct of their own character to avoid weaknesses, better with others, better decision-making.
            </ol>
            <ol>
              4. 72 questions in total; There is no right or wrong for all questions. Please choose according to your actual situation.
            </ol>
          </ul>
        </Paragraph>

        <Alert message="Test results are for reference only! If this website is helpful to you, you can click the advertisement on the website to show your support, thank you!" type="success" showIcon />
        <p></p>
        {/* <Link to="/test">{<Button type="primary">Click Here to Start Test</Button>}</Link> */}
        <Link to="/mbti">{<Button type="primary">Click Here to Start Test</Button>}</Link>

      </div>
    </div>
  );
};

export default HomePage;
