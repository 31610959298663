import axios from 'axios';
import Questions from '../models/mbtiModel';



// Function to fetch data from the API
const fetchQuestions = async (): Promise<Questions> => {
  try {
    const url = `https://api.it-sides.com/api/mbti/questions`
    // const url = `http://localhost:8787/api/mbti/questions`
    const response = await axios.get<Questions>(url);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error
      console.error('Error fetching data:', error.message);
    } else {
      // Handle non-Axios error
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const postAnswers = async (data: any): Promise<any> => {
  try {
    const url = `https://api.it-sides.com/api/mbti/answers`
    // const url = `http://localhost:8787/api/mbti/answers`
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }});
    return response;
  } catch (error) {

    console.error('There was an error!', error);
    throw error;
  }
}
export const service = {fetchQuestions, postAnswers};