import React from 'react';
import { Card } from 'antd';
import { useContext } from "react";
import { Context } from "../context";
import _ from 'lodash';
import { Flex } from 'antd';
const ResultPage = () => {
    const value: any = useContext(Context)
    const [state] = value;
    const data = state.result.data;

    const mbtiType = _.keys(data)[0];
    const description = data[mbtiType].description;
    const strengths = data[mbtiType].strengths;
    const career_preferences = data[mbtiType].career_preferences;
    const suitable_careers = data[mbtiType].suitable_careers;

    const love_preferences = data[mbtiType].love_preferences;
    const relationship_tips = data[mbtiType].relationship_tips;

    const keyChars: Array<any> = data[mbtiType].key_characteristics;

    return (
        <div>
            <h1>Result Page</h1>
            <Card title={<div>You are {mbtiType}</div>}>
                <Card type="inner" title={description} >
                    <Flex gap="middle" flex={'auto'}>
                        {
                            keyChars.map(ele => {
                                return <Card type="inner" title={ele.type} style={{ width: 280 }} >
                                    {ele.description}
                                </Card>
                            })
                        }
                    </Flex>
                </Card>
                <Card type="inner" title="Strengths" >
                    {strengths}
                </Card>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Career Preferences"
                >
                    {career_preferences}
                    <p>
                        {suitable_careers}
                    </p>
                </Card>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Love Preferences"
                >
                    {love_preferences}
                    <p>
                        {relationship_tips}
                    </p>
                </Card>
            </Card>
        </div>
    );
};

export default ResultPage;
