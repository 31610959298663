import { useContext, useState, useEffect } from "react";
import { Context } from "../context";
import { Button, List, Pagination } from "antd";
import Item from "./item/item";
import { service } from "../services/mbtiService";
import { useNavigate } from 'react-router-dom';

export default function MbtiQuestions() {

    const value: any = useContext(Context)
    const [state, dispatch] = value;
    const itemsPerPage = 1;
    const [currentPage, setCurrentPage] = useState(1);
    const data = state.questionList;
    const startIndex = (currentPage - 1) * itemsPerPage
    const paginatedData = data.slice(startIndex, startIndex + itemsPerPage)
    const navigate = useNavigate();

    const onChange = (page: number) => {
        // dispatch({ type: "editAnswer", payload: { id: page, value: 0} })
        console.log("in item onChange");
        setCurrentPage(page);
    }

    useEffect(() => {
        service.fetchQuestions().then(result => {
            dispatch({ type: "loadData", payload: result.data })
        });
    }, [dispatch]);

    const submitAnswers = async (e: any) => {
        e.preventDefault();
        service.postAnswers({answers: state.questionList}).then(response => {
            console.log(response);
            dispatch({ type: "nav2result", payload: response.data })
            navigate('/result');
        });
    }
    return (

        <div>
            <List dataSource={paginatedData} renderItem={(item: any) => <Item onChange={onChange} key={item.id} >{item}</Item>} pagination={false} />
            <Pagination defaultCurrent={1} total={data.length} onChange={onChange} current={currentPage} pageSize={itemsPerPage} showSizeChanger={false} />
            {state.enableSubmit && (
                <Button onClick={submitAnswers}>Submit</Button>
            )}
        </div>
    );

}
